import { getMinutes } from 'date-fns';

import { roundNumber } from '../../../utils';
import { CubeModel, Granularity, QueryParams } from '../../../utils/formatCubeQuery';
import getFormattedDuration from '../../../utils/getFormattedDuration';
import { AttemptState } from '../../../../../../typings/EventAttempt.interface';

export type MetricType = 'count' | 'percent' | 'rate' | 'avg' | 'max';

export type MetricName =
  // attempts
  | 'attempts_average_response_time'
  | 'attempts_max_response_time'
  | 'attempts_95_response_time'
  | 'attempts_99_response_time'
  | 'attempts_error_rate'
  | 'attempts_count'
  | 'attempts_rate'
  | 'attempts_pending_count'
  | 'attempts_delivered_count'
  | 'attempts_delivered_rate'
  | 'attempts_failed_count'
  | 'attempts_oldest_pending'

  // events
  | 'events_count'
  | 'events_rate'
  | 'events_max_rate'
  | 'events_successful_count'
  | 'events_failed_count'
  | 'events_pending_count'
  | 'events_paused_count'
  | 'events_with_scheduled_retry_count'
  | 'events_delayed_count'
  | 'events_error_rate'
  | 'events_avg_attempts'

  // requests
  | 'requests_count'
  | 'requests_rate'
  | 'requests_accepted_count'
  | 'requests_rejected_count'
  | 'requests_average_event'
  | 'requests_average_ignored';

export type TimeUnit = {
  value: number; // Length of the time period
  unit: 'days' | 'hours' | 'minutes' | 'seconds'; // Unit of the time period, code only supports hours for now
  sampling: {
    priority_display: [(date: Date) => number, number[]];
    date_format: string; // The date-fns format syntax
    value: number; // Sample length
    granularity: Granularity; // Sample granularity
  };
};

export const metric_time_units: Record<string, TimeUnit> = {
  '1h': {
    value: 1,
    unit: 'hours',
    sampling: {
      value: 1,
      granularity: 'minute',
      date_format: 'h:mm a',
      priority_display: [getMinutes, [15, 30, 45, 0]],
    },
  },
  '6h': {
    value: 6,
    unit: 'hours',
    sampling: {
      value: 5,
      granularity: 'minute',
      date_format: 'h:mm a',
      priority_display: [getMinutes, [15, 30, 45, 0]],
    },
  },
  '24h': {
    value: 24,
    unit: 'hours',
    sampling: {
      value: 30,
      granularity: 'minute',
      date_format: 'h:mm a',
      priority_display: [getMinutes, [30, 0]],
    },
  },
};

export const default_data_formatter: Record<
  MetricType,
  (v: number | string, rate_as?: 'second' | 'minute') => string
> = {
  count: (v: number) => roundNumber(v).toLocaleString(),
  percent: (v: number) => `${roundNumber(v)}%`,
  rate: (v: number, rate_as) =>
    `${roundNumber(v, 2).toLocaleString()}/${rate_as === 'minute' ? 'min' : 's'}`,
  avg: (v: number) => `${roundNumber(v).toLocaleString()}`,
  max: (v) => (typeof v === 'string' ? v : `${roundNumber(v).toLocaleString()}`),
};

export const charts_metric_time_units = Object.keys(metric_time_units).map((key) => ({
  key: key,
  label: key,
}));

interface AttemptMetric {
  dimentions?: ['destination_id'];
  filters?: {
    destination_id?: string[];
    state?: AttemptState[];
  };
}

interface EventMetric {
  dimentions?: ['webhook_id'];
  filters?: {
    webhook_id?: string[];
  };
}

interface RequestMetric {
  dimentions?: 'source_id'[];
  filters?: {
    source_id?: string[];
  };
}

export type MetricOptions = AttemptMetric | RequestMetric | EventMetric;

export const metric_definitions: Record<
  MetricName,
  {
    type: MetricType;
    resource: 'destination' | 'source' | 'webhook';
    title: string;
    description?: string;
    tooltip_label: string;
    getCubeQueryParams: (
      options: MetricOptions,
      granularity?: Granularity,
    ) => { model: CubeModel; params: QueryParams };
    valueToNumber: (v: string) => number;
    formatDataForDisplay?: (v: string | number) => string;
    formatTableTooltipData?: (
      value: number | string,
      optional_data?: {
        resource?: any;
        rate_limit: number;
      },
    ) => { label: string; value: string }[];
  }
> = {
  attempts_count: {
    type: 'count',
    resource: 'destination',
    title: 'Total attempts',
    tooltip_label: 'Attempts',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'count',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_rate: {
    type: 'rate',
    resource: 'destination',
    title: 'Attempts rate',
    tooltip_label: 'Attempts rate',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'count',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_pending_count: {
    type: 'max',
    resource: 'destination',
    title: 'Pending attempts',
    description: 'Count of pending attempts per destination',
    tooltip_label: 'Pending attempts',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'QueueDepth',
        params: {
          measure: 'maxDepth',
          filters: {
            ...options.filters,
            status: ['QUEUED'],
          },
          dimentions: options.dimentions,
          time_dimention: 'timestamp',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_delivered_count: {
    type: 'count',
    resource: 'destination',
    title: 'Delivered attempts',
    tooltip_label: 'Delivered attempts',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'deliveredCount',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'deliveredAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_delivered_rate: {
    type: 'rate',
    resource: 'destination',
    title: 'Delivered attempts rate',
    tooltip_label: 'Delivered attempts rate',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'deliveredCount',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'deliveredAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_failed_count: {
    type: 'count',
    resource: 'destination',
    title: 'Attempts with errors',
    tooltip_label: 'Failed attempts',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'failedCount',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_average_response_time: {
    type: 'avg',
    resource: 'destination',
    title: 'Avg. response latency',
    description: 'Average attempts response time',
    tooltip_label: 'Response time',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'avgResponseTime',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'respondedAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
    formatDataForDisplay: (v: number) => `${Math.round(v).toLocaleString()} ms`,
  },
  attempts_max_response_time: {
    type: 'avg',
    resource: 'destination',
    title: 'Max response latency',
    description: 'Max attempts response time',
    tooltip_label: 'Response time',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'maxResponseTime',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'respondedAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
    formatDataForDisplay: (v: number) => `${Math.round(v).toLocaleString()} ms`,
  },
  attempts_95_response_time: {
    type: 'avg',
    resource: 'destination',
    title: '95th response latency',
    description: '95th percentile attempts response time',
    tooltip_label: 'Response time',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'n95ResponseTime',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'respondedAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
    formatDataForDisplay: (v: number) => `${Math.round(v).toLocaleString()} ms`,
  },
  attempts_99_response_time: {
    type: 'avg',
    resource: 'destination',
    title: '99th response latency',
    description: '99th percentile attempts response time',
    tooltip_label: 'Response time',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'n99ResponseTime',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'respondedAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
    formatDataForDisplay: (v: number) => `${Math.round(v).toLocaleString()} ms`,
  },
  attempts_error_rate: {
    type: 'percent',
    resource: 'destination',
    title: 'Attempt error rate',
    description: 'The error rate of delivery attempts',
    tooltip_label: 'Error rate',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'Attempts',
        params: {
          measure: 'errorRate',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'respondedAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  attempts_oldest_pending: {
    type: 'max',
    resource: 'destination',
    title: 'Oldest attempts',
    description: 'Oldest currently undelivered attempt per destination',
    tooltip_label: 'Oldest attempt',
    getCubeQueryParams: (options: AttemptMetric, granularity) => {
      return {
        model: 'QueueDepth',
        params: {
          measure: 'maxAge',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'timestamp',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
    formatDataForDisplay: (data: number) => getFormattedDuration(data * 60 * 1000),
  },
  events_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Total events',
    tooltip_label: 'Events',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_rate: {
    type: 'rate',
    resource: 'webhook',
    title: 'Events rate',
    tooltip_label: 'Rate',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_max_rate: {
    type: 'max',
    resource: 'webhook',
    title: 'Max events rate',
    tooltip_label: 'Rate',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'EventsRate',
        params: {
          measure: 'max',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_successful_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Successful events',
    tooltip_label: 'Successful events',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'successfulCount',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_failed_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Failed events',
    tooltip_label: 'Failed events',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'failedCount',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_pending_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Pending events',
    description: 'Events waiting to be delivered to their destination',
    tooltip_label: 'Pending events',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: {
            ...options.filters,
            status: ['QUEUED'],
          },
          granularity,
        },
      };
    },
    formatTableTooltipData: (value) => {
      return [
        {
          label: 'Pending events',
          value: `${value}`,
        },
      ];
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_paused_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Paused events',
    tooltip_label: 'Paused',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: {
            ...options.filters,
            status: ['PAUSED'],
          },
          granularity,
        },
      };
    },
    formatTableTooltipData: (value) => {
      return [
        {
          label: 'Paused events',
          value: `${value}`,
        },
      ];
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_delayed_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Delayed events',
    tooltip_label: 'Scheduled',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: {
            ...options.filters,
            status: ['SCHEDULED'],
          },
          granularity,
        },
      };
    },
    formatTableTooltipData: (value) => {
      return [
        {
          label: 'Scheduled events',
          value: `${value}`,
        },
      ];
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_with_scheduled_retry_count: {
    type: 'count',
    resource: 'webhook',
    title: 'Events with scheduled retries',
    description: 'Events that failed and have a next attempt scheduled',
    tooltip_label: 'Events',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'countWithScheduledRetry',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    formatTableTooltipData: (value) => {
      return [
        {
          label: 'Events with scheduled retry',
          value: `${value}`,
        },
      ];
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_error_rate: {
    type: 'percent',
    resource: 'webhook',
    title: 'Events error rate',
    tooltip_label: 'Error rate',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'errorRate',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  events_avg_attempts: {
    type: 'avg',
    resource: 'webhook',
    title: 'Events average attempts',
    tooltip_label: 'Average attempts',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Events',
        params: {
          measure: 'avgAttempts',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  requests_count: {
    type: 'count',
    resource: 'source',
    title: 'Total requests',
    tooltip_label: 'Requests',
    getCubeQueryParams: (options: RequestMetric, granularity) => {
      return {
        model: 'Requests',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  requests_rate: {
    type: 'rate',
    resource: 'source',
    title: 'Requests rate',
    tooltip_label: 'Rate',
    getCubeQueryParams: (options: EventMetric, granularity) => {
      return {
        model: 'Requests',
        params: {
          measure: 'count',
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          filters: options.filters,
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  requests_accepted_count: {
    type: 'count',
    resource: 'source',
    title: 'Accepted requests',
    tooltip_label: 'Accepted requests',
    getCubeQueryParams: (options: RequestMetric, granularity) => {
      return {
        model: 'Requests',
        params: {
          measure: 'acceptedCount',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  requests_rejected_count: {
    type: 'count',
    resource: 'source',
    title: 'Rejected requests',
    tooltip_label: 'Rejected requests',
    getCubeQueryParams: (options: RequestMetric, granularity) => {
      return {
        model: 'Requests',
        params: {
          measure: 'rejectedCount',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  requests_average_event: {
    type: 'avg',
    resource: 'source',
    title: 'Average events',
    tooltip_label: 'Average events',
    getCubeQueryParams: (options: RequestMetric, granularity) => {
      return {
        model: 'Requests',
        params: {
          measure: 'avgEvents',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
  requests_average_ignored: {
    type: 'avg',
    resource: 'source',
    title: 'Average ignored events',
    tooltip_label: 'Average ignored events',
    getCubeQueryParams: (options: RequestMetric, granularity) => {
      return {
        model: 'Requests',
        params: {
          measure: 'avgIgnored',
          filters: options.filters,
          dimentions: options.dimentions,
          time_dimention: 'createdAt',
          granularity,
        },
      };
    },
    valueToNumber: (v) => parseInt(v),
  },
};

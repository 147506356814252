import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../../../utils';
import Avatar from '../../../../common/base/Avatar';
import Badge from '../../../../common/base/Badge';
import { ClickableArea } from '../../../../common/base/Button';
import { StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import Search from '../../../../common/Search';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';
import NewOrganizationModal from './NewOrganization';
import NewTeamModal from './NewTeam';
import { useToasts } from '../../../../common/Toast';
import { Team } from '../../../../../../../../typings/Team.interface';
import Tooltip from '../../../../common/base/Tooltip';

const StyledScrollableArea = styled(Div)`
  flex-grow: 1;
  overflow-y: auto;
  max-height: 219px;
  box-sizing: border-box;
  flex-grow: 1;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledProjectContent = styled(Div)<{ single_column?: boolean }>(
  ({ theme, single_column }) => css`
    width: ${single_column ? '279px' : '558px'};
    display: grid;
    height: 100%;
    grid-template-columns: ${single_column ? '1fr' : '1fr 1fr'};
    > div:first-child {
      border-right: ${theme.border};
      ${StyledCardSection}:first-of-type {
        border-radius: ${theme.radius.normal_inset} 0 0 0;
      }
    }

    > div:last-child {
      ${StyledCardSection}:first-of-type {
        border-radius: 0 ${theme.radius.normal_inset} 0 0;
      }
    }

    > div {
      max-width: 279px;
    }
  `,
);

const TeamSelection: React.FC<{ team_only?: boolean }> = ({ team_only }) => {
  const { HookdeckAPI, setAPITeamId } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { team, view, organization, organization_role } = useContext(DashboardContext);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const { query, updateSearchQuery } = useSearchQuery<{
    new?: 'organization' | 'project';
    organization_id?: string;
  }>();

  const [org_search, setOrgSearch] = useState<string>('');
  const [team_search, setTeamSearch] = useState<string>('');

  const [selected_organization, setSelectedOrganization] = useState<string | null>(
    organization ? organization?.id : null,
  );

  const { data: organizations } = useSWR(APIMethodKeys.organizations.listAll(), () =>
    HookdeckAPI.organizations.listAll(),
  );
  const { data: teams } = useSWR(APIMethodKeys.teams.listAll(), () => HookdeckAPI.teams.listAll());

  const sections = location.pathname.split('/');
  const first_id_index = sections.findIndex((section) => section.includes('_'));
  const root_path =
    first_id_index > 0 ? sections.slice(0, first_id_index).join('/') : location.pathname;
  let target_path = location.pathname === '/projects' ? '/connections' : root_path;
  if (target_path.includes('/events')) {
    target_path = `${view === 'cli' ? '/cli' : ''}/events`;
  }
  const target_location = {
    ...location,
    pathname: target_path,
    search: '',
  };

  const handleTeamChange = (team: Team) => {
    setAPITeamId(team.id);
    history.push(target_location);
    addToast('success', `You've switched to project ${team.name}`);
  };

  const filtered_organizations = organizations?.filter((org) =>
    org_search.length > 0 ? org.name.toLowerCase().indexOf(org_search.toLowerCase()) >= 0 : true,
  );

  const focused_organization =
    filtered_organizations &&
    (filtered_organizations.find((org) => org.id === selected_organization) ||
      filtered_organizations[0]);

  const filtered_teams =
    focused_organization &&
    teams?.filter((team) => team.organization_id === focused_organization.id);

  const filtered_teams_searched = filtered_teams?.filter((team) =>
    team_search.length > 0 ? team.name.toLowerCase().indexOf(team_search.toLowerCase()) >= 0 : true,
  );

  return (
    <>
      {query.new === 'organization' && (
        <NewOrganizationModal
          onClose={() => updateSearchQuery({ new: undefined })}
          onSuccess={handleTeamChange}
        />
      )}
      {query.new === 'project' && query.organization_id && (
        <NewTeamModal
          organization_id={query.organization_id}
          onClose={() => updateSearchQuery({ new: undefined, organization_id: undefined })}
          onSuccess={handleTeamChange}
        />
      )}
      <StyledProjectContent
        single_column={
          team_only || (filtered_organizations && filtered_organizations.length === 0)
        }>
        {!team_only && (
          <Div flex={{ direction: 'column' }}>
            <StyledCardSection muted p={{ x: 3, y: 1 }}>
              <Text size="xs" muted heading>
                Organizations
              </Text>
            </StyledCardSection>
            <StyledCardSection>
              <Search
                name="org_search"
                borderless
                small
                placeholder="Find an organization"
                value={org_search}
                onChange={setOrgSearch}
              />
              <Divider />
              <StyledScrollableArea p={{ x: 1, y: 2 }} flex={{ direction: 'column', gap: 1 }}>
                {filtered_organizations &&
                  filtered_organizations
                    .sort((a, b) => (a > b ? -1 : 1))
                    .map((other_organization) => (
                      <ClickableArea
                        key={other_organization.id}
                        highlight={
                          focused_organization && other_organization.id === focused_organization.id
                        }
                        onClick={() => setSelectedOrganization(other_organization.id)}
                        p={{ y: 1.5, x: 2 }}
                        rounded
                        flex={{ align: 'center', gap: 2 }}>
                        <Avatar name={other_organization.name} small />
                        <Text subtitle ellipsis>
                          {other_organization.name}
                        </Text>
                        <Div flex={{ align: 'center', gap: 2 }} m={{ l: 'auto' }}>
                          {other_organization.id === organization?.id && (
                            <Icon primary icon="success" />
                          )}
                          <Badge muted subtle small>
                            {capitalizeFirstLetter(other_organization.role)}
                          </Badge>
                        </Div>
                      </ClickableArea>
                    ))}
                {filtered_organizations && filtered_organizations.length === 0 && (
                  <Text muted m={{ x: 2, y: 1.5 }}>
                    No organizations found.
                  </Text>
                )}
              </StyledScrollableArea>
              {!user?.sso_idp_id && (
                <>
                  <Divider />
                  <ClickableArea
                    onClick={() => updateSearchQuery({ new: 'organization' })}
                    p={{ y: 2, x: 3 }}
                    flex={{ align: 'center' }}>
                    <Icon muted icon={'add_circle'} left />
                    <Text muted subtitle>
                      New Organization
                    </Text>
                  </ClickableArea>
                </>
              )}
            </StyledCardSection>
          </Div>
        )}
        {filtered_organizations && filtered_organizations?.length > 0 && (
          <Div flex={{ direction: 'column' }}>
            <StyledCardSection muted p={{ x: 3, y: 1 }}>
              <Text size="xs" muted heading>
                Projects
              </Text>
            </StyledCardSection>
            <StyledCardSection>
              <Search
                name="team_search"
                borderless
                small
                placeholder="Find a project"
                value={team_search}
                onChange={setTeamSearch}
              />
              <Divider />
              <StyledScrollableArea p={{ x: 1, y: 2 }} flex={{ direction: 'column', gap: 1 }}>
                {filtered_teams_searched &&
                  focused_organization &&
                  filtered_teams_searched
                    .filter((team) => team.organization_id === focused_organization.id)
                    .sort((a, b) => (a > b ? -1 : 1))
                    .map((other_team) => (
                      <ClickableArea
                        key={other_team.id}
                        to={{
                          ...target_location,
                          search: `?team_id=${other_team.id}`,
                        }}
                        highlight={team!.id === other_team.id}
                        linkOnClick={(e) => {
                          if (e.metaKey) {
                            return;
                          }
                          e.preventDefault();
                          handleTeamChange(other_team);
                        }}
                        p={{ y: 1.5, x: 2 }}
                        rounded
                        flex={{ align: 'center', gap: 2 }}>
                        <Icon icon="folder" muted small />
                        <Text subtitle ellipsis>
                          {other_team.name}
                        </Text>
                        <Div flex={{ align: 'center', gap: 2 }} m={{ l: 'auto' }}>
                          {other_team.id === team?.id && <Icon primary icon="success" />}
                          {other_team.private && (
                            <>
                              <Badge muted subtle small>
                                {capitalizeFirstLetter(other_team.role)}
                              </Badge>
                              <Tooltip tooltip="Private project" placement="bottom-start">
                                <Icon icon="lock" />
                              </Tooltip>
                            </>
                          )}
                        </Div>
                      </ClickableArea>
                    ))}
                {filtered_teams && filtered_teams.length === 0 && !org_search && !team_search && (
                  <Div flex={{ align: 'flex-start' }} m={{ x: 2, y: 1.5 }}>
                    <Icon icon="info" muted left />
                    <Text muted>
                      No projects within this organization, create a new project below.
                    </Text>
                  </Div>
                )}
              </StyledScrollableArea>
              {focused_organization && !organization?.workos_directory_id && (
                <>
                  <Divider />
                  <Tooltip
                    tooltip="You don't have permission to create a project, contact your admin"
                    disabled={organization_role !== 'viewer'}>
                    <ClickableArea
                      onClick={() =>
                        updateSearchQuery({
                          new: 'project',
                          organization_id: focused_organization.id,
                        })
                      }
                      disabled={organization_role === 'viewer'}
                      p={{ y: 2, x: 3 }}
                      flex={{ align: 'center' }}>
                      <Icon muted icon={'add_circle'} left />
                      <Text muted subtitle>
                        New Project
                      </Text>
                    </ClickableArea>
                  </Tooltip>
                </>
              )}
            </StyledCardSection>
          </Div>
        )}
      </StyledProjectContent>
    </>
  );
};

export default TeamSelection;

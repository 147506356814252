import { Fragment, useContext, useState } from 'react';
import { Link as LinkRouter, NavLink, Route, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { stringifyQuery } from '../../../../utils';
import isEmpty from '../../../../utils/isEmpty';
import { formatPath } from '../../../../utils/path';
import Avatar from '../../../common/base/Avatar';
import Badge from '../../../common/base/Badge';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import Search from '../../../common/Search';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import { metric_categories } from '../Metrics/metric-categories';
import { DateRange } from '../Metrics/MetricDatePicker';
import { ViewsContext, ViewsContextProvider } from '../ViewsContext';
import NavFooter, { StyledBeamerButton } from './NavFooter';
import ResourceLink from './ResourceLink';
import TeamSelection from './TeamSelection/TeamSelection';
import ViewSelector, { views } from './ViewSelector';

export const StyledDashboardNav = styled.div(
  ({ theme }) => css`
    display: flex;
    position: sticky;
    top: 0;
    max-height: 100vh;
    min-height: 100vh;
    flex-direction: column;
    background-color: ${theme.colors.surface.base.background};
    border-right: ${theme.border};
    z-index: ${theme.zindex.sticky + 2};
  `,
);

export const StyledDashboardNavSection = styled(Div)<{
  grow?: boolean;
  border?: boolean;
}>(
  ({ theme, border, grow }) => css`
    flex-grow: ${grow ? 1 : 0};
    position: relative;
    ${border &&
    css`
      &::before {
        content: '';
        height: 1px;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${theme.colors.outline.neutral};
        position: absolute;
      }
    `};
  `,
);

const StyledNavLinkButton = styled(Icon)<{ opened: boolean }>(
  ({ opened }) => css`
    ${opened &&
    css`
      display: inline-block !important;
    `};
  `,
);

export const StyledPrimaryNavLink = styled(LinkRouter)``;

export const StyledNavLinks = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    line-height: 100%;

    ${StyledNavLinkButton} {
      display: none;
    }

    > a,
    > button,
    > ${StyledBeamerButton} {
      display: flex;
      align-items: center;
      margin-top: ${theme.spacing(1)};
      position: relative;

      color: ${theme.colors.on.neutral.primary_neutral};
      svg,
      > span {
        color: ${theme.colors.on.neutral.secondary_neutral};
        fill: ${theme.colors.on.neutral.secondary_neutral};
      }
      text-decoration: none;
      padding: ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(2)};
      border-radius: ${theme.radius.normal};
      &:hover {
        background-color: ${theme.colors.surface.base.variant_surface};
        ${StyledNavLinkButton} {
          display: inline-block;
        }
      }
      &.active {
        background-color: ${theme.colors.surface.base.variant_surface};
        ${StyledNavLinkButton} {
          display: inline-block;
        }
      }

      &:focus-visible {
        outline: none;
        &::before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large};
        }
      }
    }

    ${StyledPrimaryNavLink} {
      color: ${theme.colors.on.neutral.primary};
      svg,
      > span {
        color: ${theme.colors.on.neutral.primary};
        fill: ${theme.colors.on.neutral.primary};
      }
    }
  `,
);

const StyledViewsContainer = styled(Div)(
  ({ theme }) => css`
    margin-right: -4px;
    padding-right: 4px;
    margin-bottom: -4px;
    padding-bottom: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: ${theme.pxToRem(15)};
      top: ${theme.pxToRem(8)};
      bottom: ${theme.pxToRem(8)};
      background-color: ${theme.colors.outline.neutral};
      width: 1px;
    }

    ${StyledNavLinks} {
      > a:not(.active) {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }
    }
  `,
);

const Views = ({ type, prefix }: { type: 'events' | 'requests'; prefix?: 'cli' }) => {
  const { views } = useContext(ViewsContext);

  const [search, setSearch] = useState('');

  const all_views = views.models
    .sort((a, b) => (b.name > a.name ? -1 : 1))
    .filter(({ name }) => !search || name.toLowerCase().indexOf(search.toLowerCase()) >= 0);

  return (
    <StyledNavLinks>
      {views?.models
        .filter((_, i) => i < 5)
        .map((view) => (
          <NavLink
            key={view.id}
            to={`${prefix ? `/${prefix}` : ''}/${type}/${formatPath(view.name, {
              escape_parentheses: false,
            })}`}>
            <Text subtitle size="m" ellipsis>
              {view.name}
            </Text>
          </NavLink>
        ))}
      {views?.models.length > 5 && (
        <Dropdown
          placement="right-start"
          block
          p={0}
          m={{ l: 2 }}
          parent_width={{ min: 248, max: 248 }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <ClickableArea highlight={opened} onClick={() => toggle(!opened)}>
                <Div flex={{ align: 'center', justify: 'space-between' }}>
                  <Text muted subtitle size="m" ellipsis>
                    See all {views?.models.length} views...
                  </Text>
                </Div>
              </ClickableArea>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <StyledCardSection muted p={{ x: 3, y: 1 }}>
                <Text size="xs" subtitle>
                  Saved Views
                </Text>
              </StyledCardSection>
              <StyledCardSection p={{ x: 3, y: 2 }}>
                <Search focus small value={search} onChange={setSearch} />
              </StyledCardSection>
              <StyledCardSection max_h={{ px: 204 }} scroll>
                {all_views.length === 0 && search && (
                  <Text muted p={{ x: 3, y: 2 }}>
                    No matching views...
                  </Text>
                )}
                {all_views.map((view, i) => (
                  <Fragment key={view.id}>
                    {i !== 0 && <Divider />}
                    <ClickableArea
                      w={100}
                      p={{ x: 3, y: 2 }}
                      onClick={() => toggle(false)}
                      to={`${prefix ? `/${prefix}` : ''}/${type}/${formatPath(view.name, {
                        escape_parentheses: false,
                      })}`}>
                      <Text subtitle size="m" ellipsis>
                        {view.name}
                      </Text>
                    </ClickableArea>
                  </Fragment>
                ))}
              </StyledCardSection>
            </>
          )}
        </Dropdown>
      )}
    </StyledNavLinks>
  );
};

const DashboardNav = () => {
  const { view, subscription, setNavCollapsed, nav_collapsed, organization } =
    useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const { query } = useSearchQuery<{
    date?: DateRange;
  }>();

  const { data: event_views } = useSWR(APIMethodKeys.views.list({ type: 'events' }), () =>
    HookdeckAPI.views.list({ type: 'events' }),
  );
  const { data: request_views } = useSWR(APIMethodKeys.views.list({ type: 'requests' }), () =>
    HookdeckAPI.views.list({ type: 'requests' }),
  );

  const { data: issues_count_response } = useSWR(
    APIMethodKeys.issues.count({ status: 'OPENED' }),
    () => HookdeckAPI.issues.count({ status: 'OPENED' }),
    {
      refreshInterval: 1000 * 60,
    },
  );

  const issues_count = issues_count_response?.count || 0;

  return (
    <StyledDashboardNav>
      <StyledDashboardNavSection p={{ x: 2, y: 3 }}>
        <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
          <Dropdown
            block
            placement="bottom-start"
            p={0}
            renderToggle={(opened, toggle) => (
              <ClickableArea
                on="background"
                rounded
                block
                highlight={opened}
                flex={{ align: 'center' }}
                onClick={() => toggle(!opened)}
                p={{ x: 2, y: 1.5 }}>
                <Avatar name={organization!.name} small />
                <Div m={{ l: 2 }}>
                  <Text ellipsis text_wrap={false} subtitle style={{ maxWidth: '143px' }}>
                    {organization!.name}
                  </Text>
                </Div>
              </ClickableArea>
            )}>
            <TeamSelection />
          </Dropdown>
          {!nav_collapsed && (
            <Button on="background" minimal onClick={() => setNavCollapsed(true)} p={{ x: 1.5 }}>
              <Icon muted icon="left_panel_close" />
            </Button>
          )}
        </Div>
      </StyledDashboardNavSection>
      <StyledDashboardNavSection p={{ x: 2, y: 4 }} border grow>
        <Div>
          <Text muted heading size="s" m={{ x: 2 }}>
            Configure
          </Text>
          <StyledNavLinks>
            <NavLink to="/connections">
              <Icon left icon="connections" />
              <Text subtitle size="m">
                Connections
              </Text>
            </NavLink>
          </StyledNavLinks>
          <Route path={['/connections', '/sources', '/transformations', '/destinations']}>
            <StyledViewsContainer flex={{ direction: 'column' }}>
              <Div m={{ l: 6 }}>
                <ResourceLink resource="sources" />
                <ResourceLink resource="destinations" />
                <ResourceLink resource="transformations" />
              </Div>
            </StyledViewsContainer>
          </Route>
          <Text muted heading size="s" m={{ x: 2, t: 4 }}>
            Monitor
          </Text>
          <StyledNavLinks>
            <NavLink to="/issues">
              <Icon left icon="issues" />
              <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                Issues
                {issues_count > 0 && (
                  <Badge danger subtle>
                    {issues_count || 1}
                  </Badge>
                )}
              </Text>
            </NavLink>
            <Route path={['/issues', '/issue-triggers']}>
              <StyledViewsContainer flex={{ direction: 'column' }}>
                <Div m={{ l: 6 }}>
                  <StyledNavLinks>
                    <NavLink to={`/issue-triggers`}>
                      <Text subtitle size="m" ellipsis>
                        Issue Triggers
                      </Text>
                    </NavLink>
                  </StyledNavLinks>
                </Div>
              </StyledViewsContainer>
            </Route>
            <NavLink
              to={`/metrics${
                pathname.includes('metrics') && !isEmpty(query?.date)
                  ? `?${stringifyQuery({ date: query.date })}`
                  : ''
              }`}
              isActive={() => pathname === '/metrics'}>
              <Icon left icon="metrics" />
              <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                Metrics
              </Text>
              {!subscription?.features?.includes('metrics') && (
                <Icon muted icon="upgrade" m={{ r: 0.5 }} />
              )}
            </NavLink>
            {subscription?.features?.includes('metrics') && (
              <Route path="/metrics">
                <StyledViewsContainer flex={{ direction: 'column' }}>
                  <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                    <StyledNavLinks>
                      {Object.entries(metric_categories).map(([key, { label }]) => (
                        <NavLink
                          key={key}
                          to={`/metrics/${key}${
                            pathname.includes('metrics') && !isEmpty(query?.date)
                              ? `?${stringifyQuery({ date: query.date })}`
                              : ''
                          }`}>
                          <Text subtitle size="m" ellipsis>
                            {label}
                          </Text>
                        </NavLink>
                      ))}
                    </StyledNavLinks>
                  </Div>
                </StyledViewsContainer>
              </Route>
            )}
          </StyledNavLinks>
          <Text muted heading size="s" m={{ x: 2, t: 4 }}>
            Inspect
          </Text>
          <ViewSelector />
          <StyledNavLinks>
            {views[view].links.map((link) => (
              <Fragment key={link.key}>
                <NavLink to={link.path} isActive={() => pathname === link.path}>
                  <Icon left icon={link.icon} />
                  <Text subtitle size="m" ellipsis>
                    {link.label}
                  </Text>
                </NavLink>
                {request_views && link.key === 'requests' && request_views?.count > 0 && (
                  <ViewsContextProvider
                    type="requests"
                    redirect_root={`${view === 'cli' ? '/cli' : ''}/requests`}>
                    <Route path={['/cli/requests', '/requests']}>
                      <StyledViewsContainer flex={{ direction: 'column' }}>
                        <Route path="/requests">
                          <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                            <Views type="requests" />
                          </Div>
                        </Route>
                        <Route path="/cli/requests">
                          <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                            <Views type="requests" prefix="cli" />
                          </Div>
                        </Route>
                      </StyledViewsContainer>
                    </Route>
                  </ViewsContextProvider>
                )}
                {event_views && link.key === 'events' && event_views?.count > 0 && (
                  <ViewsContextProvider
                    type="events"
                    redirect_root={`${view === 'cli' ? '/cli' : ''}/events`}>
                    <Route path={['/cli/events', '/events']}>
                      <StyledViewsContainer flex={{ direction: 'column' }}>
                        <Route path="/events">
                          <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                            <Views type="events" />
                          </Div>
                        </Route>
                        <Route path="/cli/events">
                          <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                            <Views type="events" prefix="cli" />
                          </Div>
                        </Route>
                      </StyledViewsContainer>
                    </Route>
                  </ViewsContextProvider>
                )}
              </Fragment>
            ))}
          </StyledNavLinks>
        </Div>
      </StyledDashboardNavSection>
      <NavFooter />
    </StyledDashboardNav>
  );
};

export default DashboardNav;

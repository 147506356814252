import { useCubeQuery } from '@cubejs-client/react';

import { useMemo } from 'react';
import { Query } from '../../utils/formatCubeQuery';
import useCubeApi from './useCubeApi';

export const utcToLocaleTzISO = (date: string) => {
  const input = date;
  const tzoffset = new Date(`${input}Z`).getTimezoneOffset() * 60000; //offset in milliseconds
  const local_ISO_time = new Date(new Date(`${input}Z`).getTime() - tzoffset)
    .toISOString()
    .slice(0, -1);

  return local_ISO_time;
};

const browser_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useCubeQueryLocalRawData = <T>(
  query: Query | undefined | null,
  options = {},
): { is_loading: boolean; raw_data: T[] | null; refetch: () => void; error: Error | null } => {
  const cubejs_api = useCubeApi();
  const result = useCubeQuery<T>(query as any, {
    cubejsApi: cubejs_api,
    ...options,
  });

  const raw_data_with_tz = useMemo(() => {
    if (result.isLoading || !result.resultSet) {
      return null;
    }

    if (
      !result.resultSet.query()?.timeDimensions &&
      result.resultSet.query()?.timeDimensions?.[0]
    ) {
      return null;
    }

    const { dimension: time_dimension, granularity } =
      result.resultSet.query()?.timeDimensions?.[0] || {};

    const query = result.resultSet.query();
    if (!query.timezone || query.timezone === browser_tz) {
      return null;
    }
    // We don't have access to the internal object, is there a better way to set it?
    const raw_data_with_tz = result.resultSet.rawData().map((row) => {
      if (time_dimension && row[time_dimension]) {
        row[time_dimension] = utcToLocaleTzISO(row[time_dimension]);
      }
      if (time_dimension && row[`${time_dimension}.${granularity}`]) {
        row[`${time_dimension}.${granularity}`] = utcToLocaleTzISO(row[time_dimension]);
      }

      return row;
    });
    return raw_data_with_tz;
  }, [result.resultSet, result.isLoading]);

  return {
    is_loading: result.isLoading || !raw_data_with_tz,
    raw_data: raw_data_with_tz,
    refetch: result.refetch,
    error: result.error,
  };
};

export { useCubeQueryLocalRawData };

import { useContext } from 'react';
import styled, { useTheme, css } from 'styled-components';

import LINKS from '../../../../configs/links';
import { showChat } from '../../../../utils/liveChat';
import Button from '../../../common/base/Button';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { handleLogout } from '../Nav/NavFooter';
import { UserContext } from '../../../contexts/UserContext';

const StyledOnboardingNav = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${theme.spacing(5)};
    height: 56px;
    z-index: 2;
    border-bottom: ${theme.border};

    a {
      height: fit-content;
      height: 16px;
    }

    @media only screen and (max-width: 900px) {
      padding: 0 ${theme.spacing(4)};
    }
  `,
);

const OnboardingNav: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const theme = useTheme();

  return (
    <StyledOnboardingNav>
      <a href={LINKS.landing}>
        <img src={`/images/logo-colored-${theme.mode}.svg`} height="16" />
      </a>
      <Div flex={{ justify: 'end' }} style={{ width: '1px' }}>
        <Button minimal icon="chat" onClick={showChat}>
          Get Help
        </Button>
        {user && (
          <Button m={{ l: 2 }} minimal icon="logout" onClick={() => handleLogout(HookdeckAPI)}>
            Logout
          </Button>
        )}
      </Div>
    </StyledOnboardingNav>
  );
};

export default OnboardingNav;
